import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from '@ppa/layout';

@Component({
  selector: 'ppa-root',
  template: `
    <router-outlet></router-outlet>
    <div #dynamic></div>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  @ViewChild('dynamic', { read: ViewContainerRef, static: true }) modalContainer: ViewContainerRef;

  constructor(protected modalService: ModalService) {
  }

  ngOnInit() {
    this.modalService.setModalContainerRef(this.modalContainer);
  }

}
