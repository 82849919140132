import { NotFoundComponent } from './components/not-found/not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { MainComponent } from './components/main/main.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthenticatedGuard } from '@ppa/data';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'pool',
        loadChildren: () => import('./modules/pool/pool.module').then((m) => m.PoolModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'tare-template',
        loadChildren: () => import('./modules/tare-template/tare-template.module').then((m) => m.TareTemplateModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'price-template',
        loadChildren: () => import('./modules/price-template/price-template.module').then((m) => m.PriceTemplateModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'note-template',
        loadChildren: () => import('./modules/note-template/note-template.module').then((m) => m.NoteTemplateModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'product',
        loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'product-variety',
        loadChildren: () =>
          import('./modules/product-variety/product-variety.module').then((m) => m.ProductVarietyModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'disease',
        loadChildren: () => import('./modules/disease/disease.module').then((m) => m.DiseaseModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'size_sorting',
        loadChildren: () => import('./modules/size-sorting/size-sorting.module').then((m) => m.SizeSortingModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'certificate',
        loadChildren: () => import('./modules/certificate/certificate.module').then((m) => m.CertificateModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'config',
        loadChildren: () => import('./modules/config/config.module').then((m) => m.ConfigModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'origin',
        loadChildren: () => import('./modules/origin/origin.module').then((m) => m.OriginModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'log',
        loadChildren: () => import('./modules/log/log.module').then((m) => m.LogModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'bulk-order',
        loadChildren: () => import('./modules/bulk-order/bulk-order.module').then((m) => m.BulkOrderModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'treatment',
        loadChildren: () => import('./modules/treatment/treatment.module').then((m) => m.TreatmentModule),
        canActivate: [AuthenticatedGuard],
      },
      {
        path: 'contract-template',
        loadChildren: () =>
          import('./modules/contract-template/contract-template.module').then((m) => m.ContractTemplateModule),
        canActivate: [AuthenticatedGuard],
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  providers: [],
  exports: [RouterModule],
})
export class AppRoutingModule {}
