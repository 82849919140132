export const environment = {
  env: 'acc',
  production: true,
  dataLib: {
    baseUrl: 'https://api.acc.ppaui.nl',
  },
  ui: {
    baseUrl: 'https://acc.ppaui.nl',
  },
};
