import { Component, OnInit } from '@angular/core';
import { AuthService } from '@ppa/data';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ppa-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    if (environment.env === 'dev') {
      if (document.title === ':backofficename') {
        document.title = 'Backoffice dev';
      }

      const elements = document.querySelectorAll('.version');
      for (const e in elements) {
        if (elements.hasOwnProperty(e)) {
          const element = elements[e];
          if (element.innerHTML === ':version') {
            element.innerHTML = 'development';
          }
        }
      }
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }
}
