import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { API_CONFIG, ApiInterceptor, ConfigService, ENV, JwtInterceptor } from '@ppa/data';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MainComponent } from './components/main/main.component';
import { AppRoutingModule } from './app-routing.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { ToastrModule } from 'ngx-toastr';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { environment } from '../environments/environment';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MatButtonModule } from '@angular/material/button';
import { LoadingModule, SpinnerModule } from '@ppa/layout';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatChipsModule } from "@angular/material/chips";
import localeNL from '@angular/common/locales/nl';

registerLocaleData(localeNL, 'nl');

export function appInit(config: ConfigService) {
  return () => config.load();
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent, MainComponent, DashboardComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule,
    HttpClientModule,
    TranslocoRootModule,
    TranslocoMessageFormatModule.init(),
    ToastrModule.forRoot({ positionClass: 'inline' }),
    BrowserAnimationsModule,
    MatMenuModule,
    MatListModule,
    MatToolbarModule,
    MatButtonModule,
    LoadingModule,
    SpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule
  ],
  providers: [
    {
      provide: API_CONFIG,
      useValue: environment.dataLib,
    },
    {
      provide: ENV,
      useValue: environment.env,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [ConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
