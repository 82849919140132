import { Injectable } from '@angular/core';
import { LogDataService } from '@ppa/data';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private logDataService: LogDataService) { }

  exportLog() {
    return this.logDataService.exportLog();
  }
}
