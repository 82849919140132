import { Component, OnInit } from '@angular/core';
import { AuthService } from '@ppa/data';
import { LogService } from '../../modules/pool/services/log.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ppa-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(public authService: AuthService, private logService: LogService, private router: Router) {}

  ngOnInit(): void {}

  exportLog() {
    this.logService.exportLog().toPromise();
  }
}
